import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import './ChatWidget.css';

// Use environment variable or ternary operator to switch between development and production URLs
const SOCKET_URL = process.env.NODE_ENV === 'production' 
  ? 'https://www.wayuptechn.com' 
  : 'http://localhost:8000';

const socket = io(SOCKET_URL, {
  transports: ['websocket', 'polling'],
  path: '/socket.io',
  pingTimeout: 60000,
  pingInterval: 25000,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: 5
});

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputData, setInputData] = useState({ 
    name: '', 
    message: '', 
    email: '', 
    question: '' 
  });
  const [activeForm, setActiveForm] = useState('chat'); // 'chat', 'call', or 'question'
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const messagesEndRef = useRef(null);

  // Consolidated socket connection and event handling
  useEffect(() => {
    // Clear existing listeners to prevent duplicates
    socket.removeAllListeners();
    
    // Connection status handlers
    socket.on('connect', () => {
      console.log('Connected with ID:', socket.id);
      setConnectionStatus('connected');
    });
    
    socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      setConnectionStatus('disconnected');
      if (reason === 'io server disconnect') {
        // Server disconnected us, try to reconnect
        setTimeout(() => socket.connect(), 1000);
      }
    });
    
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error.message);
      setConnectionStatus('error');
      // Manual reconnection with backoff
      setTimeout(() => socket.connect(), 5000);
    });

    // Message handlers
    socket.on('chat message', (msg) => {
      setMessages(prevMessages => [...prevMessages, msg]);
    });

    socket.on('call scheduled', (response) => {
      const message = response.success
        ? `Call scheduled successfully for ${inputData.name}`
        : `Failed to schedule call: ${response.error}`;
      setMessages(prevMessages => [...prevMessages, message]);
    });

    socket.on('question received', (response) => {
      const message = response.success
        ? `Question received: ${inputData.question}`
        : `Failed to submit question: ${response.error}`;
      setMessages(prevMessages => [...prevMessages, message]);
    });

    // Force connection attempt if not connected
    if (socket.disconnected) {
      socket.connect();
    }

    // Cleanup on component unmount
    return () => {
      socket.removeAllListeners();
      // Don't disconnect here as it might affect other components
    };
  }, [inputData.name, inputData.question]);

  // Auto-scroll to bottom of messages
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (connectionStatus !== 'connected') {
      setMessages(prev => [...prev, 'Unable to send message: Not connected to server']);
      return;
    }

    const { name, message, email, question } = inputData;

    switch(activeForm) {
      case 'chat':
        if (name && message) {
          socket.emit('chat message', `${name}: ${message}`);
          setInputData(prevData => ({ 
            ...prevData, 
            message: '' // Clear only the message, keep the name
          }));
        }
        break;
      case 'call':
        if (name && email) {
          socket.emit('schedule call', { name, email });
          setInputData(prevData => ({ 
            ...prevData, 
            email: '' // Clear only email, keep the name
          }));
          setActiveForm('chat');
        }
        break;
      case 'question':
        if (name && question) {
          socket.emit('ask question', { name, question });
          setInputData(prevData => ({ 
            ...prevData, 
            question: '' // Clear only question, keep the name
          }));
          setActiveForm('chat');
        }
        break;
      default:
        console.warn('Unknown form type');
        break;
    }
  };

  // Connection status is now always shown as connected

  return (
    <div className="chat-widget">
      <button 
        className="chat-toggle" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Close Chat' : "Let's talk!"}
      </button>
      
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h3>Chat Support</h3>
            <span className="connection-status connected">
              connected
            </span>
          </div>

          <ul className="chat-messages">
            {messages.map((msg, index) => (
              <li key={index} className="chat-message">
                {msg}
              </li>
            ))}
            <div ref={messagesEndRef} />
          </ul>

          <form onSubmit={handleSubmit} className="chat-form">
            <input
              type="text"
              name="name"
              value={inputData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className="chat-input"
              required
            />

            {activeForm === 'chat' && (
              <input
                type="text"
                name="message"
                value={inputData.message}
                onChange={handleInputChange}
                placeholder="Type a message..."
                className="chat-input"
                required
              />
            )}

            {activeForm === 'call' && (
              <input
                type="email"
                name="email"
                value={inputData.email}
                onChange={handleInputChange}
                placeholder="Your Email"
                className="chat-input"
                required
              />
            )}

            {activeForm === 'question' && (
              <textarea
                name="question"
                value={inputData.question}
                onChange={handleInputChange}
                placeholder="Type your question..."
                className="chat-input"
                required
              />
            )}

            <button 
              type="submit" 
              className="chat-send"
              disabled={connectionStatus !== 'connected'}
            >
              Send
            </button>
          </form>

          <div className="chat-actions">
            <button 
              onClick={() => setActiveForm('call')} 
              className={`chat-action-button ${activeForm === 'call' ? 'active' : ''}`}
            >
              Schedule a Call
            </button>
            <button 
              onClick={() => setActiveForm('question')} 
              className={`chat-action-button ${activeForm === 'question' ? 'active' : ''}`}
            >
              Ask a Question
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;